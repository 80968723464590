import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  getVideo(videoId){
  	return this.http.get('https://admin.videoplayer.sescdigital.org.br/json/'+videoId);
  }
}
