import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import videojs from 'video.js';
import hotkeys from 'videojs-hotkeys';

@Component({
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit {

  video$: Object;
  video: Object;
  context: Object;

  constructor(private route: ActivatedRoute, private data: DataService) {
    this.route.url.subscribe(
      url => this.context = (url[0].path)
    );
    this.route.params.subscribe( 
      params => this.video = params.id 
   	);
   }

  ngOnInit() {
  	this.data.getVideo(this.video).subscribe(
      data => {
        this.video$ = data;
        if (null != this.video$['color']) {
          document.documentElement.style.setProperty('--main-color', this.video$['color']);
        }
        hotkeys;
        var player = videojs('video');
        player.src({ type: 'application/x-mpegURL', src: this.video$['url'] });
        player.ready(function() {
          this.hotkeys({
            volumeStep: 0.1,
            seekStep: 5,
            enableModifiersForNumbers: false
          });
        });
      },
      error => {
        console.log(error);
      }
    )
  }
}
